<!-- 首页容器 -->
<template>
    <div class="index-container flex-1 flex-column">
        <Header/>
        <router-view/>
        <el-backtop :bottom="100"></el-backtop>
    </div>
</template>

<script>
import Header from '@/components/header'

export default {
    name: 'Index',
    components: { Header },

    data() {
        return {

        };
    },

    methods: {
        
    }
}
</script>
<style lang='scss' scoped>
    .index-container{
        padding-bottom: 50px;
    }
</style>